import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  sendEmailVerification,
  updateProfile,
  signInWithCustomToken,
} from "firebase/auth";
import axiosInstance from "../../utils/axiosInstance";
import {
  EyeOpenIcon,
  EyeOffIcon,
  LoginIcon,
  CreateIcon,
  CapsLockIcon,
  InfoIcon,
} from "../Icons";
import { auth } from "../utils/fireBaseConfig";
import styled from "styled-components";
import PasswordStrengthCheckUI from "./PasswordStrength";
import image21 from "../../assets/images/background5.svg";
import logo from "../../assets/logo/kepler_logo.svg";
import Google from "../../assets/coloredIcons/google.svg";
import Facebook from "../../assets/coloredIcons/facebook.svg";
import Yahoo from "../../assets/coloredIcons/yahoo.svg";
import Microsoft from "../../assets/coloredIcons/microsoft.svg";
import X from "../../assets/coloredIcons/x.svg";

import {
  Button4,
  ButtonContainer2,
  GlobalStyle,
  Copyright1,
} from "../StyledComponents";
import africa from "../../assets/images/africa1.svg";
import { Grid } from "react-loader-spinner";

const SignupPopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-image: url(${image21});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 3vh;
  margin-bottom: 3vh;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 40%;
  border: 1px solid #b6c6c5;

  @media (max-width: 992px) {
    width: 87%;
    border-radius: 5px;
  }
`;

const TabButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: 15px;
  color: ${(props) => (props.active ? "#245e41" : "#7a9795")};
  margin: 0px 15px 10px;
  padding: 5px;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  position: relative;
`;

const Underline = styled.div`
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.active ? "0" : "50%")};
  width: ${(props) => (props.active ? "100%" : "0")};
  height: 1px;
  background-color: #245e41;
  transition: all 0.3s;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupTitle = styled.h2`
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 5vh;
  color: #245e41;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  width: 100%;
`;

const PopupLabel = styled.label`
  width: 98%;
  position: absolute;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) =>
    props.focused || props.value || !props.valid ? "-15px" : "50%"};
  transform: translateY(-30%);
  font-size: ${(props) =>
    props.focused || props.value || !props.valid ? "14px" : "16px"};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value && props.valid ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;

  ${FormGroup}:focus-within & {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;

const PopupLabel1 = styled.label`
  width: 98%;
  position: absolute;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-15px" : "50%")};
  transform: translateY(-30%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;

  ${FormGroup}:focus-within & {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;

const PopupInput = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.value && !props.valid ? "red" : "#ccc")};
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background: transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const PopupInput1 = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background: transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid
      border-bottom: 1px solid #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const RequiredIndicator = styled.span`
  color: ${(props) =>
    !props.focused && props.transparent ? "transparent" : "red"};
  margin-left: 1px;
  font-size: 14px;
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;

  svg {
    color: #7a9795;
  }

  &:hover {
    svg {
      color: #245e41;
    }
  }
`;

const PopupError = styled.p`
  color: red;
  font-size: 14px;
`;

const SocialLoginButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1%;
  padding-bottom: 3%;

  @media (max-width: 600px) {
    justify-content: center;
    gap: 5px;
  }
`;

const SocialLoginButton = styled.button`
  padding: 1%;
  background-color: #f3f3f3;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 3%;

  &:hover {
    background-color: #e1e1e1;
  }
`;

const CapsLockIndicator = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 2%;

  svg {
    color: #ff4545;
  }

  &:hover {
    svg {
      color: #e90000;
    }
  }
`;

const TermsAndConditions = styled.div`
  width: 80%;
  font-size: 15px;
  color: #245e41;
  text-align: center;
  margin-top: 1rem;

  a {
    color: #245e41;
    text-decoration: underline;
    text-underline-position: under;
    &:hover {
      color: #14412d;
    }
  }

  @media (max-width: 992px) {
    width: 98%;
  }
`;

const Signup = ({
  user,
  setUser,
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
}) => {
  const [isSignup, setIsSignup] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState(isSignup ? "signup" : "login");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const [userEmailUnverified, setUserEmailUnverified] = useState(false);
  const [fullName, setFullName] = useState("");
  const emailInputRef = useRef(null);
  const [isCapsLockActive, setIsCapsLockActive] = useState(false);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userIpAddress, setUserIpAddress] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const applicationType = searchParams.get("type");

  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

  useEffect(() => {
    async function fetchUserIPAdress() {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setUserIpAddress(data.ipAddress);
      } catch (error) {
        setUserIpAddress("");
      }
    }

    fetchUserIPAdress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFirebaseErrorMessage = (errorCode) => {
    const errorMessages = {
      "auth/user-not-found": "User not found. Please create account.",
      "auth/wrong-password": "Incorrect details. Please update and try again.",
      "auth/invalid-email": "Incorrect details. Please update and try again.",
      "auth/email-already-exists":
        "This email is already in use. Please login.",
      "auth/too-many-requests": "Too many log in attempts. Try again later",
      "auth/network-request-failed": "Check your connection and try again",
      "auth/user-cancelled": "User cancelled login attempt.",
      "auth/internal-error": "Log in server error",
      "auth/account-exists-with-different-credential":
        "A user account with the same email already exists. Login with that account and link it with your Facebook account",
      // Add more error messages as needed
      default: errorCode,
    };

    return errorMessages[errorCode] || errorMessages.default;
  };

  useEffect(() => {
    setActiveTab(isSignup ? "signup" : "login");
  }, [isSignup]);

  /*useEffect(() => {
    setEmail(isSignup ? "" : "testuser@keplersolutionshq.com");
    setPassword(isSignup ? "" : "TestUser1$");
  }, [isSignup]);*/

  useEffect(() => {
    const attemptedSignup = localStorage.getItem("attemptedSignup");
    if (attemptedSignup === "true") {
      setIsSignup(false);
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.emailVerified) {
        setIsSignup(false);
        setUserEmailUnverified(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setUser]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LfJC88nAAAAAOcWC0BdRL28919IAUprLIhagDUr";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const token = await window.grecaptcha.execute(
        "6LfJC88nAAAAAOcWC0BdRL28919IAUprLIhagDUr",
        {
          action: "login",
        }
      );

      axiosInstance
        .post("/api/login", {
          email,
          userIpAddress,
          reCaptchaToken: token,
        })
        .then((response) => {
          if (response.data.success) {
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                if (!userCredential.user.emailVerified) {
                  warningNotification(
                    `Please verify your email before logging in.`
                  );
                  setError("Please verify your email before logging in.");
                  setLoading(false);
                  return;
                }
                setEmail("");
                setPassword("");
                successNotification(
                  `${userCredential.user.displayName} logged in successfully!`
                );
                const attemptedSignup = localStorage.getItem("attemptedSignup");
                if (!attemptedSignup || attemptedSignup !== "true") {
                  localStorage.setItem("attemptedSignup", "true");
                }
                setIsAccountLocked(false);

                if (applicationType === "business") {
                  navigate("/business-form");
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }, 500);
                } else if (applicationType === "home") {
                  navigate("/home-form");
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }, 500);
                } else if (applicationType === "guided") {
                  navigate("/guided-purchase");
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }, 500);
                } else {
                  navigate("/customer-dashboard");
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }, 500);
                }
                setLoading(false);
                const loggedInUser = userCredential.user;
                setUser(loggedInUser);
              })
              .catch((loginError) => {
                if (loginError.code === "auth/user-not-found") {
                  errorNotification(`User not found. Please create account.`);
                  setIsSignup(true);
                  const errorMessage = getFirebaseErrorMessage(loginError.code);
                  setError(errorMessage);
                  setLoading(false);
                } else if (loginError.code === "auth/wrong-password") {
                  errorNotification(
                    `Incorrect log in details. Please try again.`
                  );
                  const errorMessage = getFirebaseErrorMessage(loginError.code);
                  setError(errorMessage);
                  setLoading(false);
                } else if (loginError.code === "auth/email-already-in-use") {
                  errorNotification(
                    `This email is already in use. Please login.`
                  );
                  setIsSignup(false);
                  const errorMessage = getFirebaseErrorMessage(loginError.code);
                  setError(errorMessage);
                  setLoading(false);
                } else if (loginError.code === "auth/too-many-requests") {
                  errorNotification(
                    `Too many log in attempts. Please wait for some time before trying again.`
                  );
                  setIsSignup(false);
                  const errorMessage = getFirebaseErrorMessage(loginError.code);
                  setError(errorMessage);
                  setLoading(false);
                } else {
                  const errorMessage = getFirebaseErrorMessage(loginError.code);
                  setError(errorMessage);
                  setLoading(false);
                }
              });
          } else if (response.data.verifyEmail) {
            setUserEmailUnverified(true);
            infoNotification(
              `Check your email for previously sent verification link and verify your email.`
            );
            setLoading(false);
          } else if (response.data.verifyAccount) {
            infoNotification(
              `Verify your account. Check your email for verification code.`
            );
            navigate("/verify");
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 500);
            setLoading(false);
          } else if (response.data.accountDisabled) {
            warningNotification(
              `This account is locked. Contact customer support.`
            );
            setIsAccountLocked(true);
            setLoading(false);
          } else if (response.data.invalidUser) {
            warningNotification(
              `This account does not exist. Create a new account.`
            );
            setIsSignup(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.backendScoreAssessmentFailed
          ) {
            warningNotification(
              `Suspicious activity detected. Verification code sent to your inbox to keep your account safe.`
            );
            navigate("/verify");
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 500);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await fetchSignInMethodsForEmail(auth, email);

      if (userCredential.length > 0) {
        warningNotification(
          `An account with this email already exists. Please login, or verify your email, or sign up with a different email address.`
        );
        setError(
          "An account with this email already exists. Please login, or verify your email, or sign up with a different email address."
        );
        setIsSignup(false);
        setLoading(false);
        return;
      }

      if (password.length < 6) {
        setError("Password should be at least 6 characters");
        setLoading(false);
        return;
      }

      const strongPasswordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()[\]{}.,?":;|<>~/_\\])[A-Za-z\d!@#$%^&*()[\]{}.,?":;|<>~/_\\]{6,}$/;
      if (!strongPasswordPattern.test(password)) {
        setError(
          "Password should include uppercase, lowercase, numbers, and special characters"
        );
        setLoading(false);
        return;
      }

      const newUserCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(newUserCredential.user, {
        displayName: fullName,
      });

      await sendEmailVerification(newUserCredential.user);
      setPassword("");
      setFullName("");
      successNotification(
        `A verification link was sent to your email. Click on the link to complete your sign up.`
      );
      localStorage.setItem("attemptedSignup", "true");
      setLoading(false);
      setUser(newUserCredential.user);
      axiosInstance
        .post("/api/create-customer", {
          email,
          userIpAddress,
          newUser: true,
        })
        .then(async (response) => {
          if (response.data.isNewUser) {
            return;
          }
        })
        .catch((error) => {});
    } catch (signupError) {
      const errorMessage = getFirebaseErrorMessage(signupError.code);
      setError(errorMessage);
      setLoading(false);
    }
  };

  const handleAuthentication = (e) => {
    e.preventDefault();
    setError(null);
    setIsAccountLocked(false);
    setLoading(true);

    if (isSignup) {
      handleSignup(e);
    } else {
      handleLogin(e);
    }
  };

  const handleResendVerification = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        successNotification(`Verification email resent. Check your inbox.`);
        setLoading(false);
        return;
      } catch (error) {
        const errorMessage = getFirebaseErrorMessage(error.code);
        setError(errorMessage);
        setLoading(false);
        return;
      }
    } else if (email) {
      axiosInstance
        .post("/api/verify-code", {
          email,
          resendLink: true,
        })
        .then(async (response) => {
          if (response.data.resentLink) {
            try {
              await signInWithCustomToken(auth, response.data.idToken);
              const currentUser = auth.currentUser;
              if (currentUser) {
                await sendEmailVerification(currentUser);
                successNotification(
                  `Verification email resent. Check your inbox.`
                );
                setLoading(false);
                return;
              } else {
                warningNotification(`User not signed in.`);
                setLoading(false);
              }
            } catch (error) {
              const errorMessage = getFirebaseErrorMessage(error.code);
              setError(errorMessage);
              setLoading(false);
              return;
            }
          } else if (response.data.resentLink === false) {
            errorNotification(
              `Could not resend verification email. Try to login again or contact customer support.`
            );
            setLoading(false);
            return;
          }
        })
        .catch((error) => {});
      setLoading(false);
      return;
    } else {
      errorNotification(
        `Could not resend verification email. Contact customer support.`
      );
      setLoading(false);
      return;
    }
  };

  const handleSocialLogin = (provider) => {
    setError(null);
    setLoading(true);

    try {
      let selectedProvider;
      switch (provider) {
        case "google":
          selectedProvider = new GoogleAuthProvider();
          break;
        case "facebook":
          selectedProvider = new FacebookAuthProvider();
          break;
        case "x":
          selectedProvider = new TwitterAuthProvider();
          break;
        case "yahoo":
          selectedProvider = new OAuthProvider("yahoo.com");
          break;
        case "microsoft":
          selectedProvider = new OAuthProvider("microsoft.com");
          break;
        default:
          throw new Error("Unknown provider");
      }

      signInWithPopup(auth, selectedProvider)
        .then((result) => {
          const user = result.user;
          const userEmail = user.email;
          const displayName = user.displayName;
          const uid = user.uid;

          axiosInstance
            .post("/api/social-create-customer", {
              userEmail,
              displayName,
              uid,
              userIpAddress,
            })
            .then(async (response) => {
              if (response.data.existingUser) {
                await verifyEmailStatus(user);
                setUser(user);
                successNotification(
                  `Successfully logged in with your ${user.providerData[0].providerId} profile!`
                );
                navigateBasedOnApplicationType();
              } else if (response.data.isNewUser) {
                await verifyEmailStatus(user);
                setUser(user);
                successNotification(
                  `Account created successfully with your ${user.providerData[0].providerId} profile!`
                );

                localStorage.setItem("attemptedSignup", "true");
                navigateBasedOnApplicationType();
              }
            })
            .catch((error) => {
              setError("An error occurred during the login process.");
              errorNotification(`An error occurred during the login process.`);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            const errorMessage = getFirebaseErrorMessage(error.code);
            setError(errorMessage);
            warningNotification(
              `A user account with the same email already exists. Login with that account and link it with your Facebook account.`
            );
            setIsSignup(false);
          } else {
            setError("An error occurred during the login process.");
            errorNotification(`An error occurred during the login process.`);
          }
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setError("An error occurred during the login process.");
      errorNotification(`An error occurred during the login process.`);
    }
  };

  const verifyEmailStatus = async () => {
    try {
      await auth.currentUser.reload();
      const user = auth.currentUser;
      return user.emailVerified;
    } catch (error) {
      throw error;
    }
  };

  const navigateBasedOnApplicationType = () => {
    switch (applicationType) {
      case "business":
        navigate("/business-form");
        break;
      case "home":
        navigate("/home-form");
        break;
      case "guided":
        navigate("/guided-purchase");
        break;
      default:
        navigate("/customer-dashboard");
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const isValidEmail = (email) => {
    const emailRegex =
      // eslint-disable-next-line no-control-regex
      /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|\[[0-9A-Fa-f]{1,4}(?::[0-9A-Fa-f]{1,4}){7}\]))$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
    if (newEmail.trim() === "") {
      setIsEmailValid(true);
    } else if (!document.activeElement.isEqualNode(emailInputRef.current)) {
      setIsEmailValid(isValidEmail(newEmail));
    }
  };

  const getPasswordStrength = () => {
    if (password.length < 6) {
      return "weak";
    }

    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()[\]{}.,?":;|<>~/_\\]/.test(password);

    if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
      return "strong";
    }

    if (
      (hasUppercase && hasLowercase) ||
      (hasUppercase && hasNumber) ||
      (hasUppercase && hasSpecialChar) ||
      (hasLowercase && hasNumber) ||
      (hasLowercase && hasSpecialChar) ||
      (hasNumber && hasSpecialChar)
    ) {
      return "medium";
    }

    return "weak";
  };

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);
    const strength = getPasswordStrength(newPassword);
    setIsPasswordStrong(strength === "strong");
  };

  return (
    <>
      {" "}
      <GlobalStyle />
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0)",
            backdropFilter: "blur(1px)",
            paddingBottom: "50%",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      )}
      <SignupPopup>
        <PopupContent>
          <div
            style={{
              width: "100%",
              paddingTop: "0.5%",
              margin: "0",
              marginBottom: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to="/"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }, 100);
              }}
            >
              <img
                src={logo}
                alt="Kepler Logo"
                style={{
                  width: "100px",
                }}
              />
            </Link>
          </div>
          <TabContainer>
            <TabButton onClick={() => setIsSignup(true)} active={isSignup}>
              Sign up
              <Underline active={isSignup} />
            </TabButton>
            <TabButton onClick={() => setIsSignup(false)} active={!isSignup}>
              Log in
              <Underline active={!isSignup} />
            </TabButton>
          </TabContainer>
          <PopupTitle>
            {isSignup
              ? "Start by creating a free account"
              : "Log in to your account"}
          </PopupTitle>
          <SocialLoginButtons>
            <SocialLoginButton
              onClick={() => handleSocialLogin("google", applicationType)}
            >
              <img src={Google} alt="Google" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Google
              </p>
            </SocialLoginButton>
            <SocialLoginButton
              onClick={() => handleSocialLogin("facebook", applicationType)}
            >
              <img src={Facebook} alt="Facebook" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Facebook
              </p>
            </SocialLoginButton>
            <SocialLoginButton
              onClick={() => handleSocialLogin("yahoo", applicationType)}
            >
              <img src={Yahoo} alt="Yahoo" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Yahoo
              </p>
            </SocialLoginButton>
            <SocialLoginButton
              onClick={() => handleSocialLogin("x", applicationType)}
            >
              <img
                src={X}
                alt="X"
                style={{
                  margin: "4.5px",
                }}
              />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                X/Twitter
              </p>
            </SocialLoginButton>

            <SocialLoginButton
              onClick={() => handleSocialLogin("microsoft", applicationType)}
            >
              <img src={Microsoft} alt="Microsoft" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Microsoft
              </p>
            </SocialLoginButton>
          </SocialLoginButtons>
          <div
            style={{
              width: "100%",
              padding: "2%",
              paddingTop: "5%",
              paddingBottom: "8%",
              textAlign: "center",
              color: "#245e41",
              fontSize: "15px",
            }}
          >
            {isSignup ? "Or sign up with an email" : "Or log in with email"}
          </div>
          <form onSubmit={handleAuthentication}>
            {isSignup && (
              <FormGroup>
                <PopupLabel1 value={fullName} valid={fullName.trim() !== ""}>
                  Full Name (First, Middle-Initials, Last):
                  <RequiredIndicator transparent={fullName.trim() !== ""}>
                    *
                  </RequiredIndicator>
                </PopupLabel1>
                <PopupInput1
                  name="fullName"
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  autoComplete="name"
                  required
                />
              </FormGroup>
            )}
            <FormGroup>
              <PopupLabel value={email} valid={isEmailValid}>
                Email:
                <RequiredIndicator
                  transparent={email.trim() !== "" || !isSignup}
                >
                  *
                </RequiredIndicator>
              </PopupLabel>
              <PopupInput
                type="email"
                value={email}
                name="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={() => {
                  if (email.trim() !== "") {
                    setIsEmailValid(isValidEmail(email));
                  }
                }}
                valid={isEmailValid}
                ref={emailInputRef}
                required
                autoComplete="email"
              />
              {!isEmailValid && (
                <PopupError
                  style={{
                    fontSize: "12px",
                    marginLeft: "12px",
                    height: "0px",
                  }}
                >
                  Please enter a valid email address
                </PopupError>
              )}
            </FormGroup>
            <FormGroup
              style={{
                marginBottom: isSignup ? "0" : "0",
              }}
            >
              <PopupLabel1 value={password}>
                Password:
                <RequiredIndicator
                  transparent={password.trim() !== "" || !isSignup}
                >
                  *
                </RequiredIndicator>
              </PopupLabel1>

              <PopupInput1
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                onKeyUp={(e) =>
                  setIsCapsLockActive(e.getModifierState("CapsLock"))
                }
                autoComplete={isSignup ? "new-password" : "current-password"}
                required
              />
              <ToggleButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <EyeOpenIcon /> : <EyeOffIcon />}
              </ToggleButton>
              {isCapsLockActive && (
                <CapsLockIndicator>
                  <CapsLockIcon />
                </CapsLockIndicator>
              )}
            </FormGroup>
            {isSignup && !user && (
              <PasswordStrengthCheckUI password={password} />
            )}
            {error && <PopupError>{error}</PopupError>}
            {userEmailUnverified && (
              <div
                style={{
                  padding: "2%",
                  marginTop: "1.5rem",
                  marginBottom: "1.5rem",
                  textAlign: "start",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  height: "auto",
                  background: "#e8ffa4",
                  borderRadius: "5px",
                  width: "98%",
                }}
              >
                {isSignup ? (
                  <>
                    <div
                      style={{
                        margin: "0%",
                        padding: "0%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <InfoIcon />
                      <p>
                        A verification link was
                        sent to your email address. Please check your inbox/junk folder
                        and click on the link to complete the sign up process.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        margin: "0%",
                        padding: "0%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <InfoIcon />
                      <p
                        style={{
                          marginRight: "3%",
                        }}
                      >
                        A link was sent to your email address for verification.
                        Check your inbox/junk folder. Log in here to access your
                        new account after verification.
                      </p>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={handleResendVerification}
                          style={{
                            minHeight: "60px",
                            marginLeft: "2%",
                            marginRight: "1%",
                            padding: "5%",
                            border: "1px solid #b6c6c5",
                            borderRadius: "5px",
                            background: "#f6ffdc",
                            fontSize: "12px",
                            lineHeight: "1.15",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                            color: "#223614",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#245e41";
                            e.target.style.color = "#f0ffc3";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#f6ffdc";
                            e.target.style.color = "#223614";
                          }}
                        >
                          Resend verification link to email
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {isAccountLocked && (
              <div
                style={{
                  padding: "2%",
                  marginTop: "1.5rem",
                  marginBottom: "1.5rem",
                  textAlign: "start",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  height: "auto",
                  background: "#e8ffa4",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    margin: "0%",
                    padding: "0%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    color: "red",
                  }}
                >
                  <InfoIcon />
                  <p>
                    Your account was locked due to many unsuccessful
                    verification attempts. Contact customer support for
                    assistance.
                  </p>
                </div>
              </div>
            )}

            <ButtonContainer2
              style={{
                width: "100%",
                padding: "0",
                margin: "0",
                marginTop: window.innerWidth > 992 ? "10%" : "10%",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3.1rem",
              }}
            >
              <Button4
                type="submit"
                style={{
                  width: "98%",
                  padding: "0",
                }}
                disabled={
                  email === "" ||
                  password === "" ||
                  !isValidEmail(email) ||
                  (!isPasswordStrong && isSignup) ||
                  (fullName.trim() === "" && isSignup)
                }
                className={
                  email === "" ||
                  password === "" ||
                  !isValidEmail(email) ||
                  (!isPasswordStrong && isSignup) ||
                  (fullName.trim() === "" && isSignup)
                    ? "disabled"
                    : ""
                }
              >
                {isSignup ? "Create account" : "Login"}
                {isSignup ? <CreateIcon /> : <LoginIcon />}
              </Button4>
            </ButtonContainer2>
            <div
              style={{
                marginTop: "1rem",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!isSignup ? (
                <Link
                  to="/forgot-password"
                  onClick={() => {
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 100);
                  }}
                  style={{
                    color: "#245e41",
                    fontSize: "15px",
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                >
                  Forgot your password?
                </Link>
              ) : (
                <TermsAndConditions>
                  By creating an account you agree to our{" "}
                  <a href="/legal" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>{" "}
                  &{" "}
                  <a href="/legal" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </TermsAndConditions>
              )}
            </div>
          </form>
        </PopupContent>
        <Copyright1>
          &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
          <CountryFlagIcon country="Africa" width="27px" height="14px" />
          with 🤍 by Kepler Solutions
        </Copyright1>
      </SignupPopup>
    </>
  );
};

export default Signup;
