import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axiosInstance from "../utils/axiosInstance";

// Styled components for the box design
const Wrapper = styled.div`
  background-color: #29344e;
  padding: 15px;
  padding-top: 20px;
  text-align: center;
`;

const Heading = styled.h2`
  color: #cf3;
  font-size: 1.3rem;


  @media (max-width: 750px) {
    font-size: 1.1rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  padding-top: 0;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }
`;

const Box = styled.div`
  position: relative;
  width: 23%;
  height: 85px;
  margin: 1%;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  color: #cf3;
  background-color: ${(props) => props.bgColor};

  .value {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    font-family: "Major Mono Display", monospace;
    text-align: center;
    font-size: 2.2rem;
    color: #cff;

    @media (max-width: 750px) {
      font-size: 1.5rem;
    }
  }

  .title {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
      font-size: 0.7rem;
      height: 50px;
      padding-top: 5px;
    }

    @media (max-width: 750px) {
      font-size: 0.8rem;
      top: 45px;
    }
  }

  .viewMore {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    text-align: center;
    height: 24px;
    line-height: 24px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 0.8rem;
    cursor: pointer;
  }

  &:hover .viewMore {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 750px) {
    width: 80%;
    margin-bottom: 20px;
    height: 70px;
  }
`;

const PowerGenData = () => {
  const [plantData, setPlantData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAggregatedData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/growatt/total-energy");
      setPlantData(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch aggregated plant data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAggregatedData();
    const intervalId = setInterval(() => {
      fetchAggregatedData();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const formatInteger = (num) => {
    return num ? Math.round(num).toLocaleString("en-US") : "-----";
  };

  const formatDecimal = (num) => {
    return num ? Number(num.toFixed(2)).toLocaleString("en-US") : "-----";
  };

  const renderIntegerValue = (value) => {
    if (loading || error) {
      return "-----";
    }
    return formatInteger(value);
  };

    const renderDecimalValue = (value) => {
      if (loading || error) {
        return "-----";
      }
      return formatDecimal(value);
    };

  return (
    <Wrapper>
      <Heading>Our customers enjoy...</Heading>
      <Container>
        <Box bgColor="#405250">
          <div className="value">{renderDecimalValue(plantData.totalEnergy_kWh/1000)}</div>
          <div className="title">Total Energy Generated (MWh)</div>
          {/*<div className="viewMore">View more »</div>*/}
        </Box>
        <Box bgColor="#405250">
          <div className="value">
            {renderDecimalValue(plantData.totalCurrentPower_W/1000)}
          </div>
          <div className="title">Current Energy Generation (kW)</div>
          {/*<div className="viewMore">View more »</div>*/}
        </Box>
        <Box bgColor="#405250">
          <div className="value">
            {renderIntegerValue(plantData.totalPeakPower_kWp)}
          </div>
          <div className="title">Generation Capacity (kWp)</div>
          {/*<div className="viewMore">View more »</div>*/}
        </Box>
        <Box bgColor="#405250">
          <div className="value">
            {renderIntegerValue(plantData.storageCapacity_kWh)}
          </div>
          <div className="title">Storage Capacity (kWh)</div>
          {/*<div className="viewMore">View more »</div>*/}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default PowerGenData;
